import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import CadetFour from "../elements/cadet/CadetFour";
import ApiService from "../apiService/apiservice";

const CourseDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [course, setCourse] = useState({
    name: "",
    isActive: "",
    description: "",
    successStudents: "",
    totalStudents: "",
    image: "",
    programs: [],
  });

  useEffect(() => {
    ApiService.get(`/courses/${id}`)
      .then((data) => {
        const { name, isActive, description, successStudents, totalStudents, image, programs } = data.data;
        setCourse({ name, isActive, description, successStudents, totalStudents, image, programs });
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, [id]);

  return (
    <>
      <SEO title="Course Details || iCAN" />
      <Layout>
        <div className="rn-blog-details-area">
          <CadetFour name={course.name} description={course.description} program={course.programs} successStudents={course.successStudents} totalStudents={course.totalStudents} />
        </div>
      </Layout>
    </>
  );
};
export default CourseDetails;
