import React from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import AccordionOneCadet from "../accordion/AccordionOneCadet";
import PricingTwo from "../pricing/PricingTwo";
import CounterUp from "../counterup/CounterUp";

const PopupData = [
  {
    id: "01",
    image: "./images/about/contact-image.png",
    popupLink: ["https://www.youtube.com/watch?v=xshEZzpS4faQ"],
  },
];

const CadetFour = ({ name, description, program, successStudents, totalStudents }) => {
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Fees & Charges"
                  title=""
                  description=""
                />
              </div>
            </div>
            <PricingTwo
              name={name}
              description={description}
              program={program}
            />
          </div>
        </div>

        {/* <div>
          <CounterUp successStudents={successStudents} totalStudents={totalStudents} />
        </div> */}

        {/* Start Accordion Area  */}
        <div className="rn-accordion-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="FAQs"
                  title="Frequently Asked Questions ?"
                  description=""
                />
              </div>
            </div>
            <div className="row mt--35 row--20">
              <div className="col-lg-10 offset-lg-1">
                <AccordionOneCadet customStyle="" />
              </div>
            </div>
          </div>
        </div>
        {/* End Accordion Area  */}
      </div>
    </div>
  );
};

export default CadetFour;
